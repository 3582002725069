import axiosIns, {auth} from '@/libs/axios';
import store from '@/store';

class BoardService {
  getStatuses() {
    return axiosIns
      .get('project/statuses/', {
        params: {is_completed: true},
        headers: auth(),
      })
      .then(r => {
        if (r.status === 200) {
          let new_data = [];
          for (let key in r.data) {
            new_data.push({
              title: r.data[key],
              tasks: [],
            });
          }
          store.commit('board/GET_DEFAULT_STATUSES', new_data);
        }
      });
  }

  getProjectStatuses(id) {
    let params = {
      is_completed: true,
    };
    if (store.state.osBoard.selectedSprint.goal !== null) params['sprint'] = store.state.osBoard.selectedSprint.id;
    return axiosIns
      .get('project/statuses/project/' + id + '/list/', {
        params,
        headers: auth(),
      })
      .then(r => {
        if (r.status === 200) {
          store.commit(
            'board/GET_STATUSES',
            r.data.map(el => {
              return {
                ...el,
                tasks: el.tasks.map(elem => {
                  return {
                    ...elem,
                    showCardTitle: true,
                  };
                }),
              };
            })
          );
        }
      });
  }

  getProjectStatusesForTask(id) {
    return axiosIns.get('project/statuses/project/' + id + '/list/', {
      headers: auth(),
    });
  }

  changeTaskStatus(task_id, status_id) {
    return axiosIns.post('task/update/status/' + task_id + '/', {status: status_id}, {headers: auth()});
  }

  createStatusForProject(id, status) {
    return axiosIns
      .post(
        'project/statuses/create/',
        {
          project: id,
          status: status,
        },
        {headers: auth()}
      )
      .then(r => {
        this.getProjectStatuses(id).then();
      });
  }
}

export default new BoardService();
