import axiosIns, {auth} from '@/libs/axios';
import store from '@/store';
import {formatTitle, getTimeFromMinutes} from '@/assets/scripts/scripts';
import BoardService from '@/services/board.service';
import {eventBus} from '@/main';

class TasksService {
  getTasks(is_inbox = false) {
    store.commit('tasks/CHANGE_LOADING', true);
    store.commit('osBoard/GET_TASKS', []);

    let params = {};

    if (store.state.osBoard.selectedProject !== null) params['project'] = store.state.osBoard.selectedProject.id;
    if (store.state.osBoard.selectedUser.title !== null) params['assigned'] = store.state.osBoard.selectedUser.id;
    if (is_inbox) params['is_inbox'] = true;
    // eslint-disable-next-line no-prototype-builtins
    if (store.state.osBoard.selectedSprint.value) params['sprint'] = store.state.osBoard.selectedSprint.value;

    return axiosIns
      .get('task/', {
        params,
        headers: auth(),
      })
      .then(r => {
        if (r.status === 200) {
          let data = r.data.map(item => {
            return {
              ...item,
              ...{
                status:
                  item.status === null
                    ? null
                    : {
                        id: item.status.id,
                        value: formatTitle(item.status.status),
                        label: item.status.status,
                      },
                task_type: item.task_type[0].toUpperCase() + item.task_type.slice(1),
              },
            };
          });
          store.commit('osBoard/GET_TASKS', data);
          store.commit('tasks/CHANGE_LOADING', false);
        }
      });
  }

  getSubtasks(search) {
    return axiosIns.get('task/search/', {
      params: {
        search,
      },
      headers: auth(),
    });
  }

  getTasksForBacklog(id, backlog = false) {
    return axiosIns.get('task/', {
      params: {
        backlog: backlog,
        project: id,
      },
      headers: auth(),
    });
  }

  getTasksForSprint(id) {
    return axiosIns.get('task/', {
      params: {sprint: id},
      headers: auth(),
    });
  }

  createTask(data) {
    let new_data = {
      ...data,
      ...(data.project ? {project: data.project.id} : {}),
      ...(data.status ? {status: data.status.id} : {}),
      ...(data.task_type ? {task_type: data.task_type.label} : {}),
      ...(data.assigned ? {assigned: data.assigned.id} : {}),
    };

    return axiosIns.post('task/create/', new_data, {headers: auth()});
  }

  updateTask(id, data) {
    return axiosIns.patch('task/update/' + id + '/', data, {
      headers: auth(),
    });
  }

  updateStatusTaskIsCompleted(data) {
    let update_data = {
      is_completed: !data.is_completed,
    };

    return axiosIns.patch('task/update/' + data.id + '/', update_data, {
      headers: auth(),
    });
  }

  updateStatusTaskIsImportant(data) {
    let update_data = {
      is_important: !data.is_important,
    };

    return axiosIns.patch('task/update/' + data.id + '/', update_data, {
      headers: auth(),
    });
  }

  deleteTask(data) {
    return axiosIns.delete('task/delete/' + data.id + '/', {
      headers: auth(),
    });
  }

  getTask(id) {
    return axiosIns
      .get('task/' + id + '/', {
        headers: auth(),
      })
      .then(r => {
        store.commit('task/GET_TASK', {
          ...r.data,
          description:
            r.data.description.length === 0
              ? [
                  {
                    type: 'text',
                    string: '',
                    ordering: 0,
                    img: {
                      file: null,
                      url: null,
                      description: '',
                    },
                    todoList: [],
                    code: {
                      lang: '',
                      code: '',
                    },
                  },
                ]
              : r.data.description,
          estimateText: getTimeFromMinutes(r.data.estimate),
        });
        store.commit('osBoard/LOADING', false);
        BoardService.getProjectStatuses(r.data.project.id).then();
        eventBus.$emit('load-timer');
        setTimeout(() => {
          const images = document.getElementsByClassName('reader-img');
          for (let i = 0; i < images.length; i++) {
            images[i].classList.add('cursor-zoom-in');
            images[i].addEventListener('click', () => {
              store.commit('task/CHANGE_IMAGE_DATA', {
                url: images[i].src,
                maxWidth: images[i].naturalWidth,
                maxHeight: images[i].naturalHeight,
              });
            });
          }
          const images2 = document.getElementsByClassName('viewer-image');
          for (let i = 0; i < images2.length; i++) {
            images2[i].classList.add('cursor-zoom-in');
            images2[i].addEventListener('click', () => {
              store.commit('task/CHANGE_IMAGE_DATA', {
                url: images2[i].src,
                maxWidth: images2[i].naturalWidth,
                maxHeight: images2[i].naturalHeight,
              });
            });
          }
        }, 500);
      });
  }

  getHistoryTask(id) {
    return axiosIns.get('task/log/' + id + '/', {headers: auth()});
  }

  getNotes(id) {
    return axiosIns.get('task/comment/list/' + id + '/', {headers: auth()});
  }

  createNote(id, data) {
    return axiosIns.post('task/comment/' + id + '/', data, {headers: auth()});
  }

  removeNote(id) {
    return axiosIns.delete('task/comment/delete/' + id + '/', {headers: auth()});
  }

  createDescription(id, data) {
    return axiosIns.post('task/description/' + id + '/create/', data, {headers: auth()});
  }

  updateDescription(id, data) {
    return axiosIns.patch('task/description/' + id + '/update/', data, {headers: auth()});
  }

  deleteDescription(id) {
    return axiosIns.delete('task/description/' + id + '/delete/', {headers: auth()});
  }
}

export default new TasksService();
